import * as React from "react"
import Layout from '../layouts'
import {SectionHero} from '../components/sections/general'

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <SectionHero heading="404 stránka neexistuje"/>
    </Layout>
  )
}

export default NotFoundPage
