import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import * as React from 'react'
import styled from 'styled-components'
import { SectionFooter, SectionHeader } from '../components/sections/general'
import { SectionContact } from '../components/sections/general'
import GlobalStyle from '../styles'
import '../styles/font.css'

type Props = {
    children: React.ReactNode
}

const Container = styled.div`
    main {
        min-height: 100vh;
    }
`

export default function Layout({children}: Props) {
    gsap.registerPlugin(ScrollTrigger)
    return (
        <Container>
            <GlobalStyle/>
            <SectionHeader/>
            <main>
                {children}
                <SectionContact/>
            </main>
            <SectionFooter/>
        </Container>
    )
}